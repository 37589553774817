import { AxiosRequestConfig } from 'axios'

import { CreateCouponPayload, UpdateCouponData } from './types'

const getCoupons = (): AxiosRequestConfig => ({
  url: '/coupons/all',
  method: 'GET',
})

const createCoupon = (coupon: CreateCouponPayload): AxiosRequestConfig => {
  const payload = new FormData()
  const { title, accentDescription, description, image, icon, expiryDate, order, additionalText, tagsSelected, allTagsAreRequired, category, storesEnabled, tucanEnabled, toteatEnabled, skus, toteatBenefitId } = coupon
  payload.append('title', title)
  payload.append('accentDescription', accentDescription)
  if (description) payload.append('description', description)
  payload.append('image', image)
  payload.append('icon', icon)
  payload.append('expiryDate', expiryDate)
  payload.append('additionalText', additionalText)
  if (order) payload.append('order', order)
  payload.append('allTagsAreRequired', allTagsAreRequired.toString())

  if (tagsSelected) payload.append('tags', tagsSelected.map(el => el.value).join(','))

  if (category) payload.append('category', category)
  payload.append('storesEnabled', storesEnabled.toString())
  payload.append('tucanEnabled', tucanEnabled.toString())
  payload.append('toteatEnabled', toteatEnabled.toString())
  if (skus) payload.append('skus', skus.join(','))
  if (toteatBenefitId) payload.append('toteatBenefitId', toteatBenefitId)

  return {
    url: '/coupons',
    method: 'POST',
    data: payload,
  }
}

const updateCoupon = (data: UpdateCouponData): AxiosRequestConfig => {
  const payload = new FormData()
  const { title, accentDescription, description, image, icon, expiryDate, order, additionalText, tagsSelected, allTagsAreRequired, category, storesEnabled, tucanEnabled, toteatEnabled, skus, toteatBenefitId } = data.payload
  if (title) payload.append('title', title)
  if (accentDescription) payload.append('accentDescription', accentDescription)
  if (description) payload.append('description', description)
  if (image) payload.append('image', image)
  if (icon) payload.append('icon', icon)
  if (expiryDate) payload.append('expiryDate', expiryDate)
  if (order) payload.append('order', order)
  if (additionalText) payload.append('additionalText', additionalText)
  if (tagsSelected) payload.append('tags', tagsSelected.map(el => el.value).join(','))
  if (allTagsAreRequired !== undefined) payload.append('allTagsAreRequired', allTagsAreRequired.toString())

  if (category) payload.append('category', category)
  payload.append('storesEnabled', storesEnabled ? 'true' : 'false')
  payload.append('tucanEnabled', tucanEnabled ? 'true' : 'false')
  payload.append('toteatEnabled', toteatEnabled ? 'true' : 'false')
  if (skus) payload.append('skus', skus.join(','))
  if (toteatBenefitId) payload.append('toteatBenefitId', toteatBenefitId) 

  return {
    url: `/coupons/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

const deleteCoupon = (id: number): AxiosRequestConfig => ({
  url: `/coupons/${id}`,
  method: 'DELETE',
})

export { getCoupons, createCoupon, updateCoupon, deleteCoupon }
