const PRIZE = {
  REWARD_TYPE: {
    'AUTOMATIC': 'A',
    'MANUAL': 'M',
    'ALWAYS_ON': 'O',
    'SPECIAL': 'S',
  }
}

enum PrizeCategory {
  General = 'General',
  Checkout = 'Checkout',
}

export { PRIZE, PrizeCategory }
