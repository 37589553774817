import { AxiosRequestConfig } from 'axios'

import { UpdateNotificationData } from './types'

const getNotifications = (): AxiosRequestConfig => ({
  url: '/notifications/automatic',
  method: 'GET',
})

const updateNotification = (data: UpdateNotificationData): AxiosRequestConfig => {
  const payload = new FormData()
  const { title, body, prize, icon, active, activePrize, description } = data.payload
  payload.append('title', title)
  payload.append('body', body)
  payload.append('prizeTitle', prize.title)
  payload.append('additionalText', prize.additionalText)
  payload.append('shortTitle', prize.shortTitle)
  payload.append('description', description)
  if (active !== undefined) payload.append('active', active.toString())
  if (activePrize !== undefined) payload.append('activePrize', activePrize.toString())
  if (icon) payload.append('icon', icon)
  if (prize.expirationDays) payload.append('expirationDays', prize.expirationDays.toString())

  if (prize.category) payload.append('category', prize.category)
  payload.append('storesEnabled', prize.storesEnabled ? 'true' : 'false')
  payload.append('tucanEnabled', prize.tucanEnabled ? 'true' : 'false')
  payload.append('toteatEnabled', prize.toteatEnabled ? 'true' : 'false')
  if (prize.skus) payload.append('skus', prize.skus.join(','))
  if (prize.toteatBenefitId) payload.append('toteatBenefitId', prize.toteatBenefitId) 

  return {
    url: `/notifications/automatic/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

export { getNotifications, updateNotification }
