import React, { useState, useEffect } from 'react'

import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete';

import { Dialog, UploadButton, snackbar } from 'components'
import { useApiCall } from 'hooks'
import { UserPoll, UpdateUserPollDetailResponse, UserPollDetail } from '../../types'
import { getUserPollDetails, updateUserPollDetail, createUserPollDetail, deleteUserPollDetail } from '../../api'
import { useStyles } from './styles'
import { constants } from '../../constansts'
import { Tag } from 'features/Main/Tags/types'


interface CreateUserPollDialogProps {
  handleClose: () => void
  userPoll: UserPoll
  tags: Tag[]
}

const MAX_ACTIVE_ANSWERS = 6

const UserPollDetailDialog: React.FC<CreateUserPollDialogProps> = ({ handleClose, userPoll, tags }) => {
  const classes = useStyles()
  const [userPollDetails, setUserPollDetails] = useState(userPoll.detail);
  const [currentUserPollDetail, setCurrentUserPollDetail] = useState<UserPollDetail>()
  const [inputValue, setInputValue] = useState<string | File | boolean | null>('')
  const [icon, setIcon] = useState<File|null>(null)
  const [inputField, setInputField] = useState('')
  const [updateUserPollDetailApi] = useApiCall<any, UpdateUserPollDetailResponse>(
    updateUserPollDetail,
  )
  const [createUserPollDetailApi] = useApiCall<any, UpdateUserPollDetailResponse>(
    createUserPollDetail,
  )
  const [getUserPollDetailsApi, isLoading] = useApiCall<UserPoll, UserPollDetail[]>(
    getUserPollDetails,
  )
  const [deleteUserPollApi] = useApiCall<UserPollDetail, void>(deleteUserPollDetail)

  useEffect(() => {
		if (userPoll) {
      const fetchData = async () => {
        const userPollDetailsData = await getUserPollDetailsApi(userPoll)

        if (userPollDetailsData) setUserPollDetails(userPollDetailsData)
      }    
      fetchData()
		}
	}, []);

  useEffect(() => {
    if (!currentUserPollDetail) return
    if (!currentUserPollDetail.id && inputField != 'answer')
    {
      snackbar.show(`Debe asignar un titulo primero`)
      return
    }
    
    const timer = setTimeout(() => {
      const updateOrCreateUserPollDetail = async () => {
        if (icon && typeof icon.name == 'string') currentUserPollDetail.icon = icon
        if (inputField == 'tag' && typeof inputValue == 'number') currentUserPollDetail.tag = inputValue
        
        const newUserPollDetail = (currentUserPollDetail.id) ? await updateUserPollDetailApi({...currentUserPollDetail}) : await createUserPollDetailApi({...currentUserPollDetail})
        if (!currentUserPollDetail.id && newUserPollDetail && inputField == 'answer')
          setUserPollDetails(userPollDetails.map(el => {if (el.answer == inputValue) {el.id = newUserPollDetail.userPollDetail.id} return el}))

        snackbar.show(`Respuesta actualizada`)
      }
      updateOrCreateUserPollDetail()
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue, currentUserPollDetail, inputField])

  const handleTitleChange = async (e: any, idx: number, item: UserPollDetail)=> {
    if (e.target.value.length < 1)
    {
      snackbar.show(`Debe ingresar un texto`)
      return
    }
    const newUserPollDetails = userPollDetails.map((el, index) => {
      if (index == idx) {
        el.answer = e.target.value
      }
      return el
    })
    const newUserPollDetail = newUserPollDetails.find(el => item.id == el.id)
    
    setCurrentUserPollDetail(newUserPollDetail)
    setInputValue(e.target.value)
    setInputField('answer')
    setUserPollDetails(newUserPollDetails)    
  }

  const handleTagChange = (e: any, item: UserPollDetail) => {
    const newUserPollDetail = userPollDetails.map(el => {
      if (item == el) {
        el.tag = e.target.value
      }
      return el
    })

    setInputValue(e.target.value)
    setInputField('tag')
    setCurrentUserPollDetail(newUserPollDetail.find(el => item.id == el.id))
    setUserPollDetails(newUserPollDetail)    
  }

	const handleIconChange = async (file: File, item: UserPollDetail) => {
    if (file.name.split('.').pop() != 'svg')
    {
      snackbar.show(`Sólo está permitido subir archivos con extension "svg"`)
      return
    }
    const imageUrl = URL.createObjectURL(file)
    const newUserPollDetail = userPollDetails.map(el => {
      if (item == el) {
        el.iconUrl = imageUrl
      }
      return el
    })
    
    setIcon(file)
    setUserPollDetails(newUserPollDetail)
    setCurrentUserPollDetail(newUserPollDetail.find(el => item.id == el.id))
  }

  const handleChangeActive = (e: any, item: UserPollDetail) => {
    if (!item.active && userPollDetails.filter((el) => el.active==true).length == MAX_ACTIVE_ANSWERS )
    {
      snackbar.show(`Sólo está permitido tener un máximo de ${MAX_ACTIVE_ANSWERS} respuestas activas`)
      return
    }
    /*if (!item.iconUrl)
    {
      snackbar.show(`Debe tener una imagen para poder activar esta respuesta`)
      return
    }*/
    if (!item.tag)
    {
      snackbar.show(`Debe seleccinar un tag para activar esta respuesta`)
      return
    }
    
    const newUserPollDetails = userPollDetails.map(el => {
      if (item == el) {
        el.active = !el.active
      }
      return el
    })
    const newUserPollDetail = newUserPollDetails.find(el => item.id == el.id)

    if (!newUserPollDetail) return
    setInputValue(newUserPollDetail.active)
    setInputField('active')
    setUserPollDetails(newUserPollDetails)
    setCurrentUserPollDetail(newUserPollDetail)
  }

  const handleAddNewItem = () => {
    const existsEmpty = userPollDetails.find(el => el.answer == '')
    if (existsEmpty)
    {
      snackbar.show(`Tiene un elemento sin completar aún`)
      return
    }
    
    const newItem = {
      id: null,
      answer: '',
      iconUrl: '',
      active: null,
      tag: null,
      userPollId: userPoll.id
    }    
    setUserPollDetails([...userPollDetails, newItem])
  }

  const handleRemoveItem = async (item: UserPollDetail) => {
    const userPollDetailsToDelete = userPollDetails.find(el => item.id == el.id)
    if (!userPollDetailsToDelete) return

    const newUserPollDetails = userPollDetails.filter(el => el != item)
    setUserPollDetails(newUserPollDetails)

    await deleteUserPollApi(userPollDetailsToDelete)
    snackbar.show(`Respuesta eliminada`)
  }

  const handleIsChecked = (active: boolean | null | undefined) => {
    if (!active) return false
    return true
  }

  const isChangeTagDisabled = (item: UserPollDetail) => {
    if (!item.tag) return false
    
    return item.active !== null
  }

  return (
    <Dialog
      title={`${constants.TEST_NAME} respuestas`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      readOnly={true}
      contentStyle={{ minWidth: 800 }}
      style={{ minWidth: '80%' }}
      //cancelButtonText={"Cerrar"}
      //onAccept={saveUserPollDetail}
    >
      <Grid container spacing={3}>
        {userPollDetails && userPollDetails.map((el, index) => (
        <>
          <Grid item lg={7} xs={7}>
            <TextField
              label="Respuesta"
              value={el.answer}
              fullWidth
              onChange={ev => handleTitleChange(ev, index, el)}
              disabled={isLoading}
              id={`${el.id}`}
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <InputLabel className={classes.multiselectTitle}>Tag</InputLabel>
            <FormControl className={classes.fullWidth}>
              <Select
                labelId="demo-simple-select-label"
                id={`demo-simple-select-${el.id}`}
                value={el.tag}
                onChange={(ev) => handleTagChange(ev, el)}
                disabled={isChangeTagDisabled(el)}
              >
                {tags.map((tag) => <MenuItem value={tag.id}>{tag.id} - {tag.title}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
{/*          <Grid item xs={2} sm={2}>
            <div className={classes.iconUploadContainer}>
              <UploadButton
                id={`userPoll-icon-upload-button-${el.id}`}
                accept=".jpg, .jpeg, .png"
                label="Subir ícono"
                options={{userPollDetailsId: el.id}}
                onChange={(ev) => handleIconChange(ev, el)}
              />
              <div className={classes.iconUploadPreviewContainer}>
                <img src={el.iconUrl} alt="" className={classes.iconPreview} />
              </div>
            </div>
          </Grid>*/}
          <Grid item xs={1} sm={1}>
            <FormControlLabel control={<Checkbox onChange={(ev) => handleChangeActive(ev, el)} checked={handleIsChecked(el.active)} />} label="Activar" />
          </Grid>
          <Grid item xs={1} sm={1}>
            <Button onClick={() => handleRemoveItem(el)}><DeleteIcon/></Button>
          </Grid>
        </>
        ))}
        <Grid item xs={12} sm={12}>
          <Button
              onClick={handleAddNewItem}
              variant="contained"
              color="secondary"
              autoFocus
            >
              {'Agregar respuesta'}
            </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UserPollDetailDialog }
