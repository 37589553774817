import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import moment, { Moment } from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { FormControl, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Typography } from '@material-ui/core'
import { MultiSelect } from 'react-multi-select-component'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { Dialog, UploadButton, snackbar } from 'components'
import { useApiCall, useCheckFormErrors } from 'hooks'
import { STRINGS } from 'consts'
import { Tag } from 'features/Main/Tags/types'
import { resizeFile } from 'utils'

import { Coupon, UpdateCouponResponse, UpdateCouponData } from '../../types'
import { updateCoupon } from '../../api'
import { useStyles } from './styles'
import { PrizeCategory } from 'consts/prize'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  accentDescription: [
    {
      validate: (value: string) => value.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  description: [
    {
      validate: (value: string) => value.length <= 254,
      message: 'No puede superar los 254 caracteres',
    },
  ],
  expiryDate: [
    {
      validate: (value: Moment | null) => !!value && value.isSameOrAfter(moment().startOf('day')),
      message: 'La fecha de vencimiento no puede ser anterior a hoy',
    },
  ],
  order: [
    {
      validate: (value: string) => (value && Number(value) > 0) || !value,
      message: 'El orden debe ser mayor a 0',
    },
  ],
}

const requiredFields = [
  'title',
  'accentDescription',
  'image',
  'icon',
  'expiryDate',
  'additionalText',
]

interface UpdateCouponDialogProps {
  handleClose: () => void
  couponToUpdate: Coupon
  onDone: (newCoupon: Coupon) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const categories = Object.values(PrizeCategory)

const UpdateCouponDialog: React.FC<UpdateCouponDialogProps> = ({
  handleClose,
  onDone,
  couponToUpdate,
  tags,
  calcUsersByTags,
}) => {
  const classes = useStyles()

  const [toteatBenefitId, setToteatBenefitId] = useState<string>(couponToUpdate.toteatBenefitId || '')
  const [currentSku, setCurrentSku] = useState<string>('')
  const [skusList, setSkusList] = useState<string[]>(couponToUpdate.skus ? couponToUpdate.skus.map(s => s.sku) : [])
  const [storesEnabled, setStoresEnabled] = useState(couponToUpdate.storesEnabled)
  const [tucanEnabled, setTucanEnabled] = useState(couponToUpdate.tucanEnabled)
  const [toteatEnabled, setToteatEnabled] = useState(couponToUpdate.toteatEnabled)
  const [category, setCategory] = useState<PrizeCategory | undefined>(couponToUpdate.category)
  const [title, setTitle] = useState(couponToUpdate.title)
  const [accentDescription, setAccentDescription] = useState(couponToUpdate.accentDescription)
  const [additionalText, setAdditionalText] = useState(couponToUpdate.additionalText)
  const [description, setDescription] = useState(couponToUpdate.description ?? '')
  const [image, setImage] = useState<File | undefined>(undefined)
  const [imagePreview, setImagePreview] = useState(couponToUpdate.imageUrl)
  const [icon, setIcon] = useState<File | undefined>(undefined)
  const [iconPreview, setIconPreview] = useState(couponToUpdate.iconUrl)
  const [expiryDate, setExpiryDate] = useState<Moment | null>(moment(couponToUpdate.expiryDate))
  const [order, setOrder] = useState(couponToUpdate.order?.toString() ?? '')
  const [tagsSelected, setTagsSelected] = useState<any[]>([])
  const [allTagsAreRequired, setAllTagsAreRequired] = useState(couponToUpdate.allTagsAreRequired)
  const [usersByTags, setUsersByTags] = useState(0)

  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  useEffect(() => {
    if (!couponToUpdate || !couponToUpdate.tags) return

    const localTagsSelected = couponToUpdate.tags.map(el => ({ label: el.title, value: el.id }))
    setTagsSelected(localTagsSelected)

    const getUsersByTags = async () => calcUsersByTags(localTagsSelected, allTagsAreRequired)

    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
  }, [couponToUpdate])

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => {
      setUsersByTags(el)
    })
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    setAllTagsAreRequired(!allTagsAreRequired)
    setUsersByTags(total)
  }

  const [updateCouponApi, isLoading] = useApiCall<UpdateCouponData, UpdateCouponResponse>(
    updateCoupon,
  )

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value)
  }

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setDescription(e.target.value)
  }

  const handleAccentDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAccentDescription(e.target.value)
  }

  const handleAdditionalTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAdditionalText(e.target.value)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setExpiryDate(newDate)
  }

  const handleImageChange = async (file: File) => {
    setImagePreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 600, 600)
    setImage(image)
  }

  const handleIconChange = async (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 192, 192)
    setIcon(image)
  }

  const handleOrderChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setOrder(e.target.value)
  }

  const handleToteatBenefitIdChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setToteatBenefitId(e.target.value)
  }

  const handleCurrentSkuChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCurrentSku(e.target.value)
  }

  const handleAddSku = () => {
    if (currentSku.trim()) {
      setSkusList([...skusList, currentSku.trim()])
      setCurrentSku('')
    }
  }

  const handleRemoveSku = (skuToRemove: string) => {
    setSkusList(skusList.filter(sku => sku !== skuToRemove))
  }

  const fields = { title, accentDescription, description, expiryDate, order, additionalText }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onUpdateCoupon = async () => {
    const coupon = {
      title,
      accentDescription,
      description,
      image,
      icon,
      expiryDate: expiryDate?.toISOString(),
      order,
      additionalText,
      tagsSelected,
      allTagsAreRequired,
      category,
      storesEnabled,
      tucanEnabled,
      toteatEnabled,
      skus: skusList,
      toteatBenefitId,
    }
    const payload = {
      id: couponToUpdate.id,
      payload: coupon,
    }
    try {
      const { coupon: updatedCoupon } = await updateCouponApi(payload)
      onDone(updatedCoupon)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar la promo. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Editar promo"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateCoupon}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Typography variant="h6">Integracion con Partner</Typography>
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setStoresEnabled(e.target.checked)} checked={storesEnabled} />
            }
            label="Disponible en locales"
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel className={classes.multiselectTitle}>Categoria</InputLabel>
          <FormControl className={classes.fullWidth}>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value as PrizeCategory)}>
              {categories.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setTucanEnabled(e.target.checked)} checked={tucanEnabled} />
            }
            label="Disponible en Tucan"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setToteatEnabled(e.target.checked)} checked={toteatEnabled} />
            }
            label="Disponible en Toteat"
          />
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                label="SKU"
                value={currentSku}
                fullWidth
                onChange={handleCurrentSkuChange}
                disabled={isLoading}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleAddSku()
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <IconButton
                size="medium"
                onClick={handleAddSku}
                disabled={!currentSku.trim() || isLoading}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="ID Beneficio Toteat"
            value={toteatBenefitId}
            fullWidth
            onChange={handleToteatBenefitIdChange}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <List>
            {skusList.map((sku, index) => (
              <ListItem key={index}>
                <ListItemText primary={sku} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => handleRemoveSku(sku)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Título"
            value={title}
            fullWidth
            onChange={handleTitleChange}
            disabled={isLoading}
            required
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripción corta"
            value={accentDescription}
            fullWidth
            onChange={handleAccentDescriptionChange}
            disabled={isLoading}
            required
            error={errors.accentDescription.hasError}
            helperText={errors.accentDescription.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripción larga"
            value={description}
            fullWidth
            onChange={handleDescriptionChange}
            disabled={isLoading}
            multiline
            error={errors.description.hasError}
            helperText={errors.description.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Texto adicional"
            value={additionalText}
            fullWidth
            onChange={handleAdditionalTextChange}
            disabled={isLoading}
            required
            error={errors.additionalText.hasError}
            helperText={errors.additionalText.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={expiryDate}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            style={{ width: '100%' }}
            label="Vencimiento"
            minDateMessage="La fecha de vencimiento no puede ser anterior a hoy"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={order}
            onChange={handleOrderChange}
            label="Order"
            type="number"
            fullWidth
            error={errors.order.hasError}
            helperText={errors.order.message}
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.multiselectTitle}>Tag</InputLabel>
          <FormControl className={classes.multiselect}>
            <MultiSelect
              options={tagsForSelect}
              value={tagsSelected}
              onChange={handleSetTags}
              labelledBy="Seleccionar Tags"
              hasSelectAll={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={
              <Checkbox onChange={handleAllTagsAreRequiredChange} checked={allTagsAreRequired} />
            }
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.iconUploadContainer}>
            <UploadButton
              id="coupon-icon-upload-button"
              accept=".jpg, .jpeg, .png"
              label="Subir ícono"
              onChange={handleIconChange}
            />
            <div className={classes.iconUploadPreviewContainer}>
              <img src={iconPreview} alt="" className={classes.iconPreview} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.imageUploadContainer}>
            <UploadButton
              id="coupon-image-upload-button"
              accept=".jpg, .jpeg, .png"
              label="Subir imagen"
              onChange={handleImageChange}
            />
            <div className={classes.imageUploadPreviewContainer}>
              <img src={imagePreview} alt="" className={classes.imagePreview} />
            </div>
          </div>
        </Grid>
        <Grid item xs={11}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Esta acción impactará a {usersByTags} usuarios</InputLabel>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateCouponDialog }
